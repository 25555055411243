exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hodnoceni-js": () => import("./../../../src/pages/hodnoceni.js" /* webpackChunkName: "component---src-pages-hodnoceni-js" */),
  "component---src-pages-missing-js": () => import("./../../../src/pages/missing.js" /* webpackChunkName: "component---src-pages-missing-js" */),
  "component---src-templates-iwp-js": () => import("./../../../src/templates/iwp.js" /* webpackChunkName: "component---src-templates-iwp-js" */),
  "component---src-templates-magazin-category-js": () => import("./../../../src/templates/magazinCategory.js" /* webpackChunkName: "component---src-templates-magazin-category-js" */),
  "component---src-templates-magazin-js": () => import("./../../../src/templates/magazin.js" /* webpackChunkName: "component---src-templates-magazin-js" */),
  "component---src-templates-magazin-post-js": () => import("./../../../src/templates/magazinPost.js" /* webpackChunkName: "component---src-templates-magazin-post-js" */),
  "component---src-templates-pages-about-us-js": () => import("./../../../src/templates/pages/aboutUs.js" /* webpackChunkName: "component---src-templates-pages-about-us-js" */),
  "component---src-templates-pages-branch-detail-js": () => import("./../../../src/templates/pages/branchDetail.js" /* webpackChunkName: "component---src-templates-pages-branch-detail-js" */),
  "component---src-templates-pages-car-insurance-js": () => import("./../../../src/templates/pages/carInsurance.js" /* webpackChunkName: "component---src-templates-pages-car-insurance-js" */),
  "component---src-templates-pages-career-js": () => import("./../../../src/templates/pages/career.js" /* webpackChunkName: "component---src-templates-pages-career-js" */),
  "component---src-templates-pages-contact-form-js": () => import("./../../../src/templates/pages/contact-form.js" /* webpackChunkName: "component---src-templates-pages-contact-form-js" */),
  "component---src-templates-pages-contact-form-success-js": () => import("./../../../src/templates/pages/contact-form-success.js" /* webpackChunkName: "component---src-templates-pages-contact-form-success-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-hp-js": () => import("./../../../src/templates/pages/hp.js" /* webpackChunkName: "component---src-templates-pages-hp-js" */),
  "component---src-templates-pages-page-with-sections-js": () => import("./../../../src/templates/pages/pageWithSections.js" /* webpackChunkName: "component---src-templates-pages-page-with-sections-js" */),
  "component---src-templates-pages-simple-page-js": () => import("./../../../src/templates/pages/simplePage.js" /* webpackChunkName: "component---src-templates-pages-simple-page-js" */),
  "component---src-templates-pages-situation-detail-default-js": () => import("./../../../src/templates/pages/situationDetailDefault.js" /* webpackChunkName: "component---src-templates-pages-situation-detail-default-js" */),
  "component---src-templates-pages-situations-js": () => import("./../../../src/templates/pages/situations.js" /* webpackChunkName: "component---src-templates-pages-situations-js" */),
  "component---src-templates-pages-stories-js": () => import("./../../../src/templates/pages/stories.js" /* webpackChunkName: "component---src-templates-pages-stories-js" */),
  "component---src-templates-products-product-detail-js": () => import("./../../../src/templates/products/productDetail.js" /* webpackChunkName: "component---src-templates-products-product-detail-js" */)
}

